import { useState } from 'react'

import { isEmptyObject } from '../../common/utils/functions'

import { Settings } from '../useSettings/useSettings.type'
import { CustomFieldMapper } from '../../components/views/settings/helpers/parsing'

import {
   SettingsChangedData,
   SettingsErrors,
   UseSettingsItems,
} from './useSettingsItems.type'

export default function (): UseSettingsItems {
   const [changedData, setChangedData] = useState<SettingsChangedData>({})
   const [errors, setErrors] = useState<SettingsErrors>({})
   const [isSubmitted, setIsSubmitted] = useState(false)

   const onChangeCustomField = (key: string, value: any) => {
      setChangedData({
         ...changedData,
         [key]: value,
      })
   }

   const onChangeToggle = (key: string, value: any) => {
      setChangedData({
         ...changedData,
         [key]: value,
      })
   }

   const onValidate = (
      settings: Settings,
      customFields: CustomFieldMapper
   ): boolean => {
      setIsSubmitted(true)

      const settingsData = { ...settings, ...changedData }
      const errors: SettingsErrors = Object.entries(customFields).reduce(
         (errorObj, [parentKey, customFieldConfig]) => {
            // if custom field has no validate function then just skip validation
            if (!customFieldConfig?.validate) return errorObj

            // if the parent field is disabled, we just skip validation
            if (!settingsData[parentKey]) return errorObj

            const { key, validate } = customFieldConfig
            const response = validate(key, settingsData[key])

            return response
               ? {
                    ...errorObj,
                    [response.key]: response.error,
                 }
               : errorObj
         },
         {}
      )

      setErrors(errors)

      return isEmptyObject(errors)
   }

   return {
      changedData,
      errors,
      isSubmitted,
      onChangeCustomField,
      onChangeToggle,
      onValidate,
   }
}
