import { useEffect, useState } from 'react'

import { useQuery, useMutation } from 'react-query'
import { AxiosResponse, AxiosError } from 'axios'
import { UseTableData } from '../../types'
import { useAPI } from '../'
import { delayedReset } from '../../common/utils/functions'

export default function (): UseTableData {
   const request = useAPI()
   const [pageOptions, setPageOptions] = useState({})

   const [config, setConfig] = useState({
      LIST: '',
      COUNT: '',
      UPDATE: '',
      DELETE: '',
      CREATE: '',
      cacheIdentifier: '',
      key: '',
   })

   const cacheIdentifier = config.cacheIdentifier
   const [error, setErrors] = useState('')

   async function getItemsFromAPI(options: {}): Promise<AxiosResponse> {
      if (config.cacheIdentifier) {
         return request.post(config.LIST, options)
      }
   }

   const {
      isError: isErrorUpdate,
      isLoading: isUpdating,
      isSuccess: isSuccessUpdate,
      mutate: mutateUpdate,
      reset: resetUpdate,
      error: errorUpdate,
   } = useMutation(updateItemsAPI, {
      onSettled: async () => {
         refresh()
         delayedReset(resetUpdate)
      },
   })

   const {
      isError: isErrorRemove,
      isLoading: isRemoving,
      isSuccess: isSuccessRemove,
      mutate: mutateRemove,
      reset: resetRemove,
   } = useMutation(removeItemsAPI, {
      onSettled: async () => {
         refresh()
         delayedReset(resetRemove)
      },
      onError: (error: AxiosError<{ Message: string }>) => {
         setErrors(
            error.response.data.ModelState[''].shift() ||
               'There was an unknown server error'
         )
      },
   })

   const [msg, setMsg] = useState(null)
   const {
      isError: isErrorAdd,
      isLoading: isAdding,
      isSuccess: isSuccessAdd,
      mutate: mutateAdd,
      reset: resetAdd,
   } = useMutation(addItemsAPI, {
      onSettled: async (x) => {
         // refresh();
         delayedReset(resetAdd)
         setMsg(x)
      },
      onError: (error: AxiosError<{ Message: string }>) => {
         setErrors(
            error.response.data.ModelState[''].shift() ||
               'There was an unknown server error'
         )
      },
   })

   async function updateItemsAPI(obj: {}): Promise<AxiosResponse> {
      return request.post(config.UPDATE, obj)
   }

   async function removeItemsAPI(value: any): Promise<AxiosResponse> {
      return request.post(config.DELETE, value)
   }

   async function addItemsAPI(value: any): Promise<AxiosResponse> {
      return request.post(config.CREATE, value)
   }

   const setPath = (path, cacheIdentifier) => {
      {
         path &&
            setConfig({
               LIST: path.LIST,
               COUNT: path.COUNT,
               UPDATE: path.UPDATE,
               DELETE: path.DELETE,
               CREATE: path.CREATE,
               cacheIdentifier,
               key: cacheIdentifier[0].toUpperCase() + cacheIdentifier.slice(1),
            })
      }
   }

   const {
      data,
      refetch,
      isLoading,
      isRefetching,
      refetch: refresh,
   } = useQuery<Promise<AxiosResponse>>(cacheIdentifier, () =>
      getItemsFromAPI({ ...pageOptions, isForMultiselectFilter: true })
   )

   const list = (obj) => {
      setPageOptions(obj)
   }

   const update = async (obj: any) => {
      mutateUpdate(obj)
   }

   const remove = async (obj: any) => {
      mutateRemove(obj)
   }

   const add = async (obj: any) => {
      mutateAdd(obj)
   }

   useEffect(() => {
      refetch({ cancelRefetch: false })
   }, [pageOptions])

   return {
      data: data?.data || [],
      msg,
      list,
      setPath,
      remove,
      update,
      add,
      refetch,
      error,
      setPageOptions,
      isLoading,
      isRefetching,
      isRemoving,
      isSuccessRemove,
      isErrorRemove,
      isSuccessUpdate,
      isErrorUpdate,
      isSuccessAdd,
      isErrorAdd,
      isUpdating,
      isAdding,
      errorUpdate:
         errorUpdate?.response?.data?.ModelState[
            Object.keys(errorUpdate?.response?.data?.ModelState)[0]
         ]?.shift(),
   }
}
