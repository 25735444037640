import React, { FC } from 'react'

import svgLogo from '../../assets/images/shareMyToolbox_Web_Logo.svg';
import svgTextLogo from '../../assets/images/logo_text.svg';

interface Props {
    textOnly?: boolean
    width?: string;
}

const Logo: FC<Props> = props => {
    const { textOnly = false, width = '180px' } = props;

    return <img style={{ width }} src={textOnly ? svgTextLogo : svgLogo} alt='ShareMyToolbox' />;
}

export default Logo