import { format } from 'date-fns'
import { FORMATS } from '../../constants/datetime'

const sortObjectsByAlpha = (array, objectProp) => {
   array.sort(function (a, b) {
      if (a[objectProp] < b[objectProp]) {
         return -1
      }
      if (a[objectProp] > b[objectProp]) {
         return 1
      }
      return 0
   })
   return array
}

const truncate = (input: string, max: number = 10) => {
   if (input && input.length > max) {
      return input.substring(0, max) + '...'
   }
   return input
}

const truncateMiddle = (input: string, max: number = 30) => {
   if (input && input.length > max) {
      return (
         input.substring(0, max - 9) +
         '...' +
         input.substring(input.length - 9, input.length)
      )
   }
   return input
}

const capitalize = (str: string) => {
   const lower = str.toLowerCase()
   return str.charAt(0).toUpperCase() + lower.slice(1)
}

const delayedReset = (cb: Function, timeout: number = 3000) => {
   setTimeout(() => {
      cb()
   }, timeout)
}

const formatDate = (date: string | Date) => {
   var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

   if (month.length < 2) month = '0' + month
   if (day.length < 2) day = '0' + day

   return [year, month, day].join('/')
}

const currencyFormatter = (currency: number) => {
   const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
   })

   return formatter.format(currency)
}

const statusColourScheme = (status: string) => {
   return {
      availableStatus: status === 'Available',
      loanedStatus: status === 'Loaned',
      pendingStatus: status === 'Pending',
      unavailableStatus: status === 'Unavailable',
      defaultStatus: status === 'default',
      infoStatus: status === 'info',
   }
}

const statusDescIdScheme = (StatusDesc: string) => {
   return StatusDesc === 'Available'
      ? 1
      : StatusDesc === 'Unavailable'
      ? 2
      : StatusDesc === 'Loaned'
      ? 4
      : StatusDesc === 'Borrowed'
      ? 8
      : StatusDesc === 'Pending'
      ? 10
      : null
}

const buildAddress = (iAddress: any) => {
   let set_address = ''
   if (iAddress) {
      if (iAddress.line1) {
         set_address += iAddress.line1 + ', '
      }
      if (iAddress.line2) {
         set_address += iAddress.line2 + ', '
      }
      if (iAddress.line3) {
         set_address += iAddress.line3 + ', '
      }
      if (iAddress.city) {
         set_address += iAddress.city + ', '
      }
      if (iAddress.county) {
         set_address += iAddress.county + ', '
      }

      if (iAddress.country) {
         set_address += iAddress.country + ', '
      }
      if (iAddress.postCode) {
         set_address += iAddress.postCode
      }
   } else {
      set_address = 'Address not available'
   }
   return set_address
}

const isEmptyObject = (obj?: object) => {
   if (!obj) return false

   return !Object.getOwnPropertyNames(obj).length
}

const populateArray = (
   min: number,
   max: number,
   incriment: number,
   stopAtNumber: number
) => {
   let arr = []
   for (let i = min; i <= max; i += incriment) {
      if (i >= stopAtNumber) {
         break
      }
      arr.push(i)
   }
   return arr
}

const renderDateOrNothing = (date: any) => {
   if (!date.value) {
      return '-'
   }

   return format(new Date(date.value), FORMATS.filterDateUI)
}

const formatLocaleDate = (
   dateString: string | Date,
   locale: string = 'en-GB'
) => {
   const dateOptions: any = { day: 'numeric', month: 'short', year: 'numeric' }
   if (
      new Date(dateString).toLocaleDateString(locale, dateOptions) ===
      'Invalid Date'
   ) {
      return null
   } else {
      return new Date(dateString).toLocaleDateString(locale, dateOptions)
   }
}

const formatUsDate = (d: string | Date) => {
   const formattedDate = new Date(d).toLocaleString('en-US', { hour12: false })
   return formattedDate.substring(0, formattedDate.indexOf(', '))
}

const removeMinYear = (value: string, minYear: number = 1970) => {
   const parts = value.split('/')
   const yearInt = parseInt(parts[parts.length - 1])
   if (yearInt <= minYear || yearInt === 2099) {
      return '-'
   }
   return value
}

const parseDate = (d: Date | string) => {
   const newDate: any = new Date(d)
   return Date.parse(newDate)
}

const subtractYears = (numOfYears: number, date = new Date()) => {
   date.setFullYear(date.getFullYear() - numOfYears)

   return date
}

// This const disables keyboard input
const disableKeyboardInput = () => {
   window.addEventListener('keydown', preventDefault)
   window.addEventListener('keypress', preventDefault)
   window.addEventListener('keyup', preventDefault)
}

// This const re-enables keyboard input
const enableKeyboardInput = () => {
   window.removeEventListener('keydown', preventDefault)
   window.removeEventListener('keypress', preventDefault)
   window.removeEventListener('keyup', preventDefault)
}

// This const prevents the default behavior of keyboard events
const preventDefault = (event) => {
   event.preventDefault()
}

const isBrowser = () => typeof window !== 'undefined'

const objectsAreEqual = <T extends object>(obj1: T, obj2: T) => {
   const keys1 = Object.keys(obj1)
   const keys2 = Object.keys(obj2)

   if (keys1.length !== keys2.length) {
      return false
   }

   for (const key of keys1) {
      if (obj1[key as keyof T] !== obj2[key as keyof T]) {
         return false
      }
   }

   return true
}

export {
   sortObjectsByAlpha,
   truncate,
   truncateMiddle,
   capitalize,
   delayedReset,
   formatDate,
   currencyFormatter,
   statusColourScheme,
   statusDescIdScheme,
   buildAddress,
   isEmptyObject,
   populateArray,
   renderDateOrNothing,
   formatLocaleDate,
   formatUsDate,
   removeMinYear,
   parseDate,
   subtractYears,
   disableKeyboardInput,
   enableKeyboardInput,
   preventDefault,
   isBrowser,
   objectsAreEqual,
}
