import React, { useEffect, useState, MouseEvent } from 'react'
import classnames from 'classnames'

import * as styles from './kitItemSelector.module.scss'
import { Item, ToolTypes, Severity } from '../../../types'
import { useSettings } from '../../../hooks'
import { TableWrapper } from '../../common/table/TableWrapper'
import { IconButton, LoadingOverlay, Search, SnackBar } from '../../common'
import { useItemGetItemsForKit } from '../../../hooks'
import useItemKitRemove from '../../../hooks/useItemKit/useItemKitRemove'
import { WarningModal } from '../../reusableModals/warningModal'
import useItemKitAdd from '../../../hooks/useItemKit/useItemKitAdd'

enum ViewName {
   KIT_ITEMS = 1,
   ADD_TO_KIT = 2,
}

interface Props {
   kitId: number
   itemForm: any
}

const KitItemSelector: React.FC<Props> = ({ kitId, itemForm }) => {
   const { settings } = useSettings()

   const {
      itemsForKitList,
      isLoadingItems,
      refreshItemsForKitList,
      paginateKitItems,
      isRefetchingItemsForKit,
      itemsForAddToKitList,
      refreshItemsForAddToKit,
      getAddToKitItems,
      isRefetchingItemsForAddToKIt,
      isLoadingItemsForAddToKit,
   } = useItemGetItemsForKit()

   const { removeFromKit, isRemoveFromKitSuccess, resetRemoveSuccess } =
      useItemKitRemove()

   const { addToKit, isAddKitSuccess, resetAddSuccess } = useItemKitAdd()

   const { EnableLocationTracking } = settings

   const [searchQuery, setSearchQuery] = useState(null)

   const [itemToDelete, setItemToDelete] = useState<number>(null)
   const [isSnackbarVisible, setIsSnackbarVisible] = useState(false)

   const [currentSkip, setCurrentSkip] = useState(0)
   const [currentTake, setCurrentTake] = useState(10)

   const [currentSkipAddToKit, setCurrentSkipAddToKit] = useState(0)
   const [currentTakeAddToKit, setCurrentTakeAddToKit] = useState(10)

   const [selectedItemQuantity, setSelectedItemQuantity] = useState<string>('1')

   const defaultAddItemListQuery = {
      defaultLocationID: itemForm.DefaultLocationID,
      status: itemForm.Status,
      assignedToUserID: itemForm.AssignedToUserID,
      requestedLocationId: itemForm.RequestedLocationId,
      locationID: itemForm.LocationID,
      skip: null,
      take: null,
      query: null,
   }

   useEffect(() => {
      if (itemsForAddToKitList && searchQuery !== null) {
         refreshItemsForAddToKit()
      }
   }, [searchQuery])

   useEffect(() => {
      if (isRemoveFromKitSuccess !== null) {
         setIsSnackbarVisible(true)
      }
      // if success refresh tables
      if (isRemoveFromKitSuccess) {
         refreshItemsForKitList()
         refreshItemsForAddToKit()
      }
   }, [isRemoveFromKitSuccess])

   useEffect(() => {
      if (isAddKitSuccess !== null) {
         setIsSnackbarVisible(true)
         // if success refresh tables
         if (isAddKitSuccess) {
            refreshItemsForAddToKit()
            refreshItemsForKitList()
            setSelectedItemQuantity('1')
         }
      }
   }, [isAddKitSuccess])

   useEffect(() => {
      if (kitId) {
         // get full list for client side pagination items already in kit
         paginateKitItems({
            skip: null,
            take: null,
            query: null,
            kitID: kitId,
         })
      }
      // get full list of items to add to kit
      getAddToKitItems(defaultAddItemListQuery)
   }, [])

   // manage kit slice array from paged data on initial render
   useEffect(() => {
      if (itemsForKitList) {
         //setPagedTableData(itemsForKitList);
         handleGetItems('', currentSkip, currentTake)
      }
   }, [itemsForKitList])

   // add to kit slice array from paged data on initial render
   useEffect(() => {
      if (itemsForAddToKitList) {
         handleGetItemsToAddToKit('', currentSkipAddToKit, currentTakeAddToKit)
      }
   }, [itemsForAddToKitList])

   const showRemovePrompt = (kitPrimeKey: number) => {
      setItemToDelete(kitPrimeKey)
      setIsDeletePrompt(true)
   }

   const handleRemoveFromKit = () => {
      removeFromKit(itemToDelete)
      setIsDeletePrompt(false)
   }

   const handleAddItemToKit = (selectedItem: Item) => {
      const itemDetails = {
         KitId: kitId,
         KitItems: [
            {
               kitItemId: selectedItem?.Id,
               Quantity: selectedItemQuantity ?? '1',
               ToolLoanId: selectedItem?.ToolLoanId,
            },
         ],
      }
      addToKit(itemDetails)
   }

   const handleAddItemQuantity = (e: React.ChangeEvent<HTMLSelectElement>) => {
      setSelectedItemQuantity(e.target.value)
   }

   const generateSnackbarMessage = () => {
      if (isRemoveFromKitSuccess) return 'Item Removed From Kit'
      if (isRemoveFromKitSuccess === false) return 'Error Removing Item'
      if (isAddKitSuccess) return 'Item Added To Kit'
      if (isAddKitSuccess === false) return 'Error Adding Item'
   }

   const generateSnackbarSeverity = () => {
      if (isRemoveFromKitSuccess || isAddKitSuccess) {
         return Severity.SUCCESS
      } else {
         return Severity.ERROR
      }
   }

   const closeAndResetSnackBar = () => {
      setIsSnackbarVisible(false)
      resetRemoveSuccess()
      resetAddSuccess()
   }

   const kitItemColumns = [
      {
         Header: 'Action',
         accessor: 'Id',
         maxWidth: 100,
         minWidth: 30,
         align: 'center',
         Cell: ({ row }) => {
            const rowItem = row?.original as Item

            return (
               <IconButton
                  id="delete"
                  action={() => showRemovePrompt(rowItem?.KitPrimeKey)}
               />
            )
         },
      },

      {
         Header: 'Title',
         accessor: 'Title',
         minWidth: 250,
      },
      {
         Header: 'Type',
         accessor: 'ToolType',
         id: 'ToolType',
         minWidth: 150,
         Cell: ({ row }) => ToolTypes[row?.original?.ToolType],
      },
      {
         Header: 'Kit Quantity',
         accessor: 'KitQuantity',
         id: 'KitQuantity',
         minWidth: 250,
      },
      {
         Header: 'Manufacturer',
         accessor: 'Manufacturer',
         id: 'Manufacturer',
         minWidth: 250,
      },
      {
         Header: 'Model No.',
         accessor: 'ModelNumber',
         id: 'ModelNumber',
         minWidth: 250,
      },
      {
         Header: 'Serial No.',
         accessor: 'SerialNumber',
         id: 'SerialNumber',
         minWidth: 250,
      },
      {
         Header: 'Barcode',
         accessor: 'Barcode',
         id: 'Barcode',
      },
      {
         Header: 'Status',
         accessor: 'StatusDesc',
         id: 'Status',
      },
      {
         Header: 'Loaned/Pending To',
         accessor: (row) => {
            if (row.AssignedToUser === null) {
               return ''
            }
            return row.AssignedToUser
         },
         id: 'AssignedToUser',
         minWidth: 250,
      },
      ...(!!EnableLocationTracking
         ? [
              {
                 Header: 'Return Warehouse',
                 accessor: 'DefaultLocation',
                 id: 'DefaultLocation',
                 minWidth: 250,
              },
              {
                 Header: 'Current/Pending Location',
                 accessor: 'CurrentLocation',
                 id: 'CurrentLocation',
                 minWidth: 250,
              },
           ]
         : []),
   ]

   const addToKitColumns = [
      {
         Header: 'Action',
         accessor: 'Id',
         maxWidth: 100,
         minWidth: 30,
         align: 'center',
         Cell: ({ row }) => {
            const rowItem = row?.original as Item

            return (
               <IconButton
                  id="add-to-kit"
                  action={() => handleAddItemToKit(rowItem)}
               />
            )
         },
      },

      {
         Header: 'Select Quantity',
         accessor: 'Quantity',
         minWidth: 150,
         maxWidth: 150,
         Cell: ({ row }) => {
            const rowItem = row?.original as Item
            return (
               <select
                  style={{ width: '100%' }}
                  onChange={(e) => handleAddItemQuantity(e)}
                  value={selectedItemQuantity}
               >
                  {rowItem.ToolType == ToolTypes['Standard Item'] && (
                     <option value={1}>1</option>
                  )}
                  {rowItem.ToolType == ToolTypes['Quantity Item'] &&
                     Array.from(Array(rowItem.StatusQuantity)).map((q, i) => (
                        <option key={i} value={i + 1}>
                           {i + 1}
                        </option>
                     ))}
               </select>
            )
         },
      },
      {
         Header: 'Available Quantity',
         accessor: 'StatusQuantity',
         id: 'AvailableQuantity',
         minWidth: 150,
      },
      {
         Header: 'Title',
         accessor: 'Title',
         id: 'Title',
         minWidth: 250,
      },
      {
         Header: 'Description',
         accessor: 'Description',
         id: 'Description',
         minWidth: 250,
      },
      {
         Header: 'Type',
         accessor: 'ToolTypeDesc',
         id: 'Type',
      },
      {
         Header: 'Total Quantity',
         accessor: 'TotalQuantity',
         id: 'TotalQuantity',
      },
      {
         Header: 'Manufacturer',
         accessor: 'Manufacturer',
         id: 'Manufacturer',
      },
      {
         Header: 'Model No.',
         accessor: 'ModelNumber',
         id: 'ModelNumber',
      },
      {
         Header: 'Serial No.',
         accessor: 'SerialNumber',
         id: 'SerialNumber',
      },
      {
         Header: 'Barcode',
         accessor: 'Barcode',
         id: 'Barcode',
      },
      {
         Header: 'Status',
         accessor: 'StatusDesc',
         id: 'Status',
      },
      {
         Header: 'Loaned/Pending To',
         accessor: (row) => {
            if (row.AssignedToUser === null) {
               return ''
            }
            return row.AssignedToUser
         },
         id: 'AssignedToUser',
         minWidth: 250,
      },
      ...(!!EnableLocationTracking
         ? [
              {
                 Header: 'Return Warehouse',
                 accessor: 'DefaultLocation',
                 id: 'DefaultLocation',
                 minWidth: 250,
              },
              {
                 Header: 'Current/Pending Location',
                 accessor: 'CurrentLocation',
                 id: 'CurrentLocation',
                 minWidth: 250,
              },
           ]
         : []),
   ]

   const [isDeletePrompt, setIsDeletePrompt] = useState(false)
   const [selectedView, setSelectedView] = useState<ViewName>(
      ViewName.KIT_ITEMS
   )

   const [pagedTableData, setPagedTableData] = useState<[]>([])
   const [pagedTableAddToKitData, setPageTableAddToKitData] = useState<[]>([])

   const handleTabClick = (
      viewName: ViewName,
      event: MouseEvent<HTMLButtonElement>
   ) => {
      event.preventDefault()
      setSelectedView(viewName)
   }

   const handleGetItems = (
      query?: string,
      skip: number = 0,
      take: number = 10
   ) => {
      setCurrentSkip(skip) // client pagination
      setCurrentTake(take) // client pagination

      const pagedData = itemsForKitList.slice(skip, take + skip)
      setPagedTableData(pagedData)
   }

   const handleGetItemsToAddToKit = (
      query?: string,
      skip?: number,
      take?: number
   ) => {
      setCurrentSkipAddToKit(skip) // client pagination
      setCurrentTakeAddToKit(take) // client pagination

      const pagedData = itemsForAddToKitList.slice(skip, take + skip)
      setPageTableAddToKitData(pagedData)
   }

   const handleSearch = (
      query?: string,
      skip?: number,
      take?: number,
      filters?: {}
   ) => {
      if (itemsForAddToKitList && query !== null) {
         getAddToKitItems({
            ...defaultAddItemListQuery,
            skip: null,
            take: null,
            query,
         })
      }
   }

   const [searchMode, setSearchMode] = useState(false)

   const showLoadingSpinner =
      isRefetchingItemsForKit ||
      isLoadingItems ||
      isRefetchingItemsForAddToKIt ||
      isLoadingItemsForAddToKit

   return (
      <>
         {showLoadingSpinner && <LoadingOverlay />}
         <div className={styles.tabs}>
            <button
               className={classnames(styles.tab, {
                  [styles.tabActive]: selectedView === ViewName.KIT_ITEMS,
               })}
               onClick={(event) => handleTabClick(ViewName.KIT_ITEMS, event)}
            >
               Kit Items
            </button>
            <button
               className={classnames(styles.tab, {
                  [styles.tabActive]: selectedView === ViewName.ADD_TO_KIT,
               })}
               onClick={(event) => handleTabClick(ViewName.ADD_TO_KIT, event)}
            >
               Add To Kit
            </button>
         </div>

         <div className={styles.itemList}>
            <div
               className={
                  selectedView === ViewName.KIT_ITEMS ? '' : styles.nonActiveTab
               }
            >
               {
                  <TableWrapper
                     columns={kitItemColumns}
                     data={pagedTableData as []}
                     getItems={handleGetItems}
                     skipItems={currentSkip}
                     takeItems={currentTake}
                     totalCount={itemsForKitList?.length}
                     extraClassName=""
                     isLoading={isLoadingItems}
                     isPageReset={false}
                     query=""
                     searchCriteria=""
                     searchMode
                     setItemId={() => {}}
                     setPageSized={() => null}
                     setSearchMode={() => null}
                     hideResultsTitle
                  />
               }
            </div>

            <div
               className={
                  selectedView === ViewName.ADD_TO_KIT
                     ? ''
                     : styles.nonActiveTab
               }
            >
               <div className={styles.searchWrapper}>
                  <div className={styles.searchWrapperSearch}>
                     <Search
                        handleQuery={handleSearch}
                        searchMode={searchMode}
                        setSearchMode={setSearchMode}
                        pageSized={10}
                        placeHolder={''}
                        setSearchCriteria={setSearchQuery}
                     />
                  </div>
               </div>

               <TableWrapper
                  columns={addToKitColumns}
                  data={pagedTableAddToKitData as []}
                  getItems={handleGetItemsToAddToKit}
                  skipItems={currentSkipAddToKit}
                  takeItems={currentTakeAddToKit}
                  totalCount={itemsForAddToKitList?.length} // this is a hack with no total figure coming from back end
                  extraClassName=""
                  isLoading={false}
                  isPageReset={false}
                  query={searchQuery}
                  searchCriteria={searchQuery}
                  searchMode={searchMode}
                  setItemId={() => {}}
                  setPageSized={() => null}
                  setSearchMode={setSearchMode}
                  hideResultsTitle
               />
            </div>
         </div>
         {isDeletePrompt && (
            <WarningModal
               isModalOpen={isDeletePrompt}
               handleClose={() => setIsDeletePrompt(false)}
               title={'Delete Confirmation'}
               message={'Are you sure you want to remove this item from Kit?'}
               primaryButtonTitle={'Delete'}
               primaryButtonMethod={handleRemoveFromKit}
               primaryButtonVariant={'danger'}
               secondaryButtonTitle={'Cancel'}
               secondaryButtonMethod={() => setIsDeletePrompt(false)}
               secondaryButtonVariant={'tertiary'}
            />
         )}
         {isSnackbarVisible &&
            (isRemoveFromKitSuccess !== null || isAddKitSuccess !== null) && (
               <SnackBar
                  message={generateSnackbarMessage()}
                  open={isSnackbarVisible}
                  onClose={closeAndResetSnackBar}
                  severity={generateSnackbarSeverity()}
                  disableAutoClose={false}
               />
            )}
      </>
   )
}

export default KitItemSelector
