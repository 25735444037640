import React from 'react'
import { Row, Col } from 'react-grid-system'
import DateInput from '../../common/form/fields/dateInput/dateInput'
import Dropdown from '../../common/form/fields/dropdown/dropdown'
import { BorrowViewProps } from '../actionsWorkflow.type'

export const BorrowView = ({
   handleDropdownChange,
   handleDateChange,
   locationOptions,
   cartOptions,
   settings,
}: BorrowViewProps) => (
   <Row gutterWidth={20}>
      {settings.EnableLocationTracking && (
         <Col md={6} lg={6}>
            <Dropdown
               name="NewLocation"
               onChange={(option) => handleDropdownChange('Location', option)}
               options={locationOptions}
               id="NewLocation"
               label="NewLocation"
               placeholder="New Location"
               isMulti={false}
            />
         </Col>
      )}
      <Col md={6} lg={6}>
         <DateInput
            id="DueBackDate"
            label="Due back (optional)"
            onChange={(date) => handleDateChange('DueBack', date)}
            selectedDate={
               !!cartOptions?.dueBackDate
                  ? new Date(Date.parse(cartOptions?.dueBackDate))
                  : null
            }
            minDate={new Date()}
         />
      </Col>
   </Row>
)
