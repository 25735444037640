
import {NavItems} from '../../hooks/useNavigation/useNavigation.type';
import { ROUTES } from '../routes';

// sub nav lists
import { historyNavList } from './subnavigation/history';
import { settingsNavList } from './subnavigation/settings';
import { profileNavList } from './subnavigation/profile';
import { itemsNavList } from './subnavigation/items';
import { actionsNavList } from './subnavigation/actions'; // may be re-introduced
import { helpNavList } from './subnavigation/help';


const environment = process.env.NODE_ENV;
const isDevEnvironment = environment === 'development';

export const navList: NavItems = [
    {
        icon: 'dashboard',
        label: 'Dashboard',
        route: ROUTES.dashboard,
    },
    {
        icon: 'subscriptions',
        label: 'Subscriptions',
        route: ROUTES.subscriptions,
    },
    {
        icon: 'connections',
        label: 'Users',
        route: ROUTES.users,
    },
    {
        icon: 'global-prices',
        label: 'Global Prices',
        route: ROUTES.global_prices,
    },
    {
        icon: 'discounts',
        label: 'Discounts',
        route: ROUTES.discounts,
    },
    {
        icon: 'items',
        children: itemsNavList,
        label: 'Items',
        route: ROUTES.items,
    },
    // {
    //     icon: 'actions',
    //     // children: actionsNavList,
    //     label: 'Actions',
    //     route: ROUTES.actions_loan // this route is switched to ROUTES.actions_borrow for employee role in getPermittedNavItems in navigation.ts
    // },
    {
        icon: 'actions',
        label: 'Actions',
        route: ROUTES.actions
    },
    {
        icon: 'connections',
        label: 'Connections',
        route: ROUTES.connections,
    },
    {
        icon: 'map-trans',
        iconSize: 30,
        label: 'Locations',
        route: ROUTES.locations,
    },
    {
        icon: 'audit',
        label: 'Audits',
        route: ROUTES.audits,
    },
    {
        icon: 'notes',
        label: 'Notes',
        route: ROUTES.notes,
    },
    {
        children: historyNavList,
        icon: 'history',
        label: 'History',
        route: ROUTES.history,
    },
    {
        children: settingsNavList,
        icon: 'settings',
        label: 'Settings',
        route: ROUTES.settings,
    },
    {
        children: profileNavList,
        icon: 'profile',
        label: 'Profile',
        route: ROUTES.profile,
    },
    {
        icon: 'subscriptions',
        label: 'Subscription',
        route: ROUTES.profile_subscriptions,
    },
    {
        children: helpNavList,
        icon: 'help',
        label: 'Help',
        route: ROUTES.help,
    },
    {
        icon: 'logout',
        label: 'Sign Out',
        route: ROUTES.logout,
    }
];
