import React, { memo } from "react";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";

import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import { ImageViewerProps } from './imageViewer.type';

const ImageViewer = ({ open, setOpen, images }: ImageViewerProps): JSX.Element =>  (
    <Lightbox
        open={open}
        close={() => setOpen(false)}
        zoom={{
            maxZoomPixelRatio: 8,
            scrollToZoom: true,
        }}
        plugins={images?.length > 1 ? [Zoom, Fullscreen, Thumbnails] : [Zoom, Fullscreen]}
        slides={images?.length > 0 ? images : []}
    />
);

export default memo(ImageViewer);