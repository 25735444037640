import React from 'react'

import Logo from '../../../../common/logo'
import { Footer } from '../../../../common/footer'
import * as styles from './authFlowWrapper.module.scss'
import { NetworkAlert } from '../../../../common'
import { AuthFlowWrapperProps } from './authFlowWrapper.types'

export const AuthFlowWrapper = ({
   callToAction,
   children,
   description,
   footer,
   heading,
}: AuthFlowWrapperProps) => (
   <>
      <NetworkAlert />
      <div className={styles.wrapper}>
         <div className={styles.inner}>
            <div className={styles.logo}>
               <Logo />
            </div>
            {heading && <h1 className={styles.heading}>{heading}</h1>}
            {description && <p className={styles.description}>{description}</p>}
            {callToAction && (
               <h2 className={styles.callToAction}>{callToAction}</h2>
            )}
            <div>{children}</div>

            {footer && <div className={styles.footerContent}>{footer}</div>}
         </div>
         <div className={styles.footer}>
            <Footer />
         </div>
      </div>
   </>
)
