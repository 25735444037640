// extracted by mini-css-extract-plugin
export var addImageButton = "crudForm-module--add-image-button--3ed62";
export var addItemsDraggable = "crudForm-module--add-items-draggable--e99b2";
export var addToKitModalDropdown = "crudForm-module--addToKitModalDropdown--1b731";
export var addToKitModalMessageWrapper = "crudForm-module--addToKitModalMessageWrapper--f552a";
export var basicForm = "crudForm-module--basic-form--3c2aa";
export var buttonContainer = "crudForm-module--button-container--2f5ef";
export var buttonItem = "crudForm-module--button-item--b1f6e";
export var centerText = "crudForm-module--center-text--ab03c";
export var crudItemDescription = "crudForm-module--crudItem-description--849fd";
export var crudItemDescriptionBefore = "crudForm-module--crudItem-description-before--8e6b0";
export var crudItemFirst = "crudForm-module--crud-item-first--0536b";
export var crudItemImage = "crudForm-module--crudItem-image--0c1fa";
export var crudItemToggle = "crudForm-module--crudItem-toggle--3604a";
export var crudItemToggleInfo = "crudForm-module--crudItem-toggleInfo--77775";
export var crudItemWrapper = "crudForm-module--crudItem-wrapper--18a7f";