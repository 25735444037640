// extracted by mini-css-extract-plugin
export var attachmentBtnContent = "notes-module--attachmentBtnContent--38676";
export var attachmentHeader = "notes-module--attachmentHeader--1d9ec";
export var attachmentInner = "notes-module--attachmentInner--4add6";
export var attachmentLoadingSpinner = "notes-module--attachmentLoadingSpinner--f0f0c";
export var attachmentWrapper = "notes-module--attachmentWrapper--17690";
export var completedInputWrapper = "notes-module--completedInputWrapper--69bd3";
export var completedInputWrapperMobile = "notes-module--completedInputWrapper-mobile--14df8";
export var coordinatesButton = "notes-module--coordinates-button--9cb25";
export var currency = "notes-module--currency--6c883";
export var deleteForm = "notes-module--delete-form--26acf";
export var deleteIcon = "notes-module--deleteIcon--02f9b";
export var description = "notes-module--description--9123f";
export var divider = "notes-module--divider--35179";
export var fileText = "notes-module--fileText--b2053";
export var form = "notes-module--form--f71c5";
export var icon = "notes-module--icon--d77ba";
export var importDownloadExample = "notes-module--import-download-example--9a1a3";
export var importForm = "notes-module--import-form--54ae1";
export var infoText = "notes-module--infoText--a7444";
export var locationsWrapper = "notes-module--locationsWrapper--3eb04";
export var noteInfoLeft = "notes-module--noteInfoLeft--4e4c3";
export var noteInfoRight = "notes-module--noteInfoRight--2a1e4";
export var semiBold = "notes-module--semiBold--3a2fd";
export var toggle = "notes-module--toggle--4439d";
export var toggleColumn = "notes-module--toggleColumn--fd456";
export var toggleField = "notes-module--toggle-field--c9c34";
export var toggleLabel = "notes-module--toggle-label--a925c";
export var veryBold = "notes-module--veryBold--32b9c";
export var warehouseDescription = "notes-module--warehouse-description--f9306";