// extracted by mini-css-extract-plugin
export var acceptDecline = "notificationItem-module--accept-decline--4c5fd";
export var acceptDeclineButton = "notificationItem-module--accept-decline-button--9e244";
export var content = "notificationItem-module--content--decd4";
export var created = "notificationItem-module--created--f5176";
export var dataLabel = "notificationItem-module--data-label--c8db7";
export var description = "notificationItem-module--description--8675c";
export var dismiss = "notificationItem-module--dismiss--0b016";
export var image = "notificationItem-module--image--15300";
export var infoItems = "notificationItem-module--info-items--c4baa";
export var keyValue = "notificationItem-module--key-value--ccce4";
export var location = "notificationItem-module--location--0c801";
export var notificationNoteWrapper = "notificationItem-module--notificationNoteWrapper--80a75";
export var wrapper = "notificationItem-module--wrapper--639a7";