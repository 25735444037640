import React, { memo, useMemo } from 'react'

import { PageHeader } from '../common'
import { LayoutLoggedIn } from '../views/layout'

type UnauthorisedViewProps = {
   isLoggedIn: boolean
}

const UnauthorisedView = ({
   isLoggedIn,
}: UnauthorisedViewProps): JSX.Element => {
   const content = useMemo(
      () => (
         <>
            <PageHeader title="Unauthorised" />
            <p>
               You do not have permission to see this page. Please try another.
            </p>
         </>
      ),
      []
   )
   return (
      <>{isLoggedIn ? <LayoutLoggedIn>{content}</LayoutLoggedIn> : content}</>
   )
}
export default memo(UnauthorisedView)
