import React, {
   Dispatch,
   SetStateAction,
   useEffect,
   useMemo,
   useRef,
   useState,
} from 'react'
import { NumericFormat } from 'react-number-format'
import { Col, Row } from 'react-grid-system'
import {
   DateInput,
   Dropdown,
   Input,
   Textarea,
   Toggle,
} from '../../common/form/fields'
import {
   useNoteMutations,
   useForm,
   useSettings,
   useAttachments,
} from '../../../hooks'

import * as yup from 'yup'
import * as noteStyles from './notes.module.scss'
import { Modal, Button, LoadingOverlay, SnackBar } from '../../common/'
import {
   convertNoteTypesToOptions,
   convertUserItemsToNoteDropdownOptions,
} from '../../common/filters/helpers'

import { Option } from '../../common/form/fields/dropdown/dropdown.type'

import { Item } from '../../../hooks/useUserItems/useUserItems.type'

import { NoteAttachmentPicker } from './noteAttachmentPicker'
import { useMediaQuery } from 'react-responsive'
import { WarningModal } from '../../reusableModals/warningModal'
import { truncate } from '../../../common/utils/functions'
import { Severity } from '../../../types'
import { FileAttachment } from './noteTypes'
import { ToolNotePermission } from '../../../types'
import { CrudItemsParentView } from '../../../enums/CrudItemsEnums'
import { NoteType } from '../../../hooks/useNoteTypes/useNoteTypes.type'
import { ItemButton } from '../../common/modal/types'

interface Props {
   selectedNote: any
   setSelectedNote: any
   isEditModalOpen: boolean
   setIsEditModalOpen: Dispatch<SetStateAction<boolean>>
   selectedItem: Item
   setSelectedItem: Dispatch<SetStateAction<Item>>
   selectedNoteType: Option
   setSelectedNoteType: Dispatch<SetStateAction<Option>>
   defaultNoteType?: Option
   refetchNotes: () => void
   getUpdateStatus?: (status: {}) => void
   setIsNewItemNote?: Dispatch<SetStateAction<boolean>>
   isNewItemNote?: boolean
   crudItemsParentPageView?: CrudItemsParentView | null
   toolLoanId?: string | number
   noteTypes: NoteType[]
   items: Item[]
}

const defaultNote = {
   Id: '',
   ToolId: null,
   NoteTypeId: null,
   NoteText: '',
   DueDate: null,
   Completed: false,
   CompletedDate: '',
   Odometer: '',
   Hours: '',
   CompletedCost: '',
   Attachments: [],
   ToolLoanId: '',
}

const defaultItem = {
   ID: '',
   Title: '',
   Barcode: '',
   SerialNumber: '',
   CustomText1Value: '',
   ToolLoanId: '',
}

const defaultNoteType = {
   label: '',
   value: '',
}

export const AddEditNoteModal: React.FC<Props> = ({
   selectedNote = defaultNote,
   setSelectedNote,
   isEditModalOpen,
   setIsEditModalOpen,
   selectedItem = defaultItem,
   setSelectedItem,
   selectedNoteType = defaultNoteType,
   setSelectedNoteType,
   refetchNotes,
   getUpdateStatus,
   setIsNewItemNote,
   isNewItemNote, // if new note selected from an item in items page
   crudItemsParentPageView,
   toolLoanId, // this is only needed if opened from within the crud items modal and within items page (it's not my fault)
   noteTypes,
   items,
}) => {
   const errorMsg = 'This field is required'
   const isMobileLayout = useMediaQuery({ query: '(max-width: 767px)' })

   const { settings } = useSettings()
   const attachmentRef = useRef<HTMLInputElement>(null)
   const {
      upload,
      attachmentData,
      isUploading,
      isUploadError,
      removeAttachment,
      isDeleteAttachmentPending,
   } = useAttachments()
   const [noteCompleted, setNoteCompleted] = useState<boolean>(
      selectedNote?.Completed ?? false
   )

   const [isAttachmentWarningModalOpen, setIsAttachmentWarningModalOpen] =
      useState<boolean>(false)
   const [isDeleteNoteWarningModalOpen, setIsDeleteNoteWarningModalOpen] =
      useState<boolean>(false)
   const [importFiles, setImportFiles] = useState<FileList | undefined>()
   const [attachments, setAttachments] = useState<FileAttachment[]>([])
   const [attachmentToRemove, setAttachmentToRemove] =
      useState<FileAttachment | null>(null)
   const [snackBarMessage, setSnackBarMessage] = useState<string>('')
   const [snackBarSeverity, setSnackBarSeverity] = useState<Severity>(null)
   const [noteTextValue, setNoteTextValue] = useState<string>(
      selectedNote?.NoteText
   )
   const [isReadonly, setIsReadonly] = useState<boolean>(false)

   const noteSchema = yup.object().shape({
      ToolId: yup.number().typeError(errorMsg).required(errorMsg),
      NoteTypeId: yup.number().typeError(errorMsg).required(errorMsg),
      CompletedDate: noteCompleted
         ? yup.date().typeError(errorMsg).required(errorMsg)
         : null,
      CompletedBy: noteCompleted
         ? yup.string().typeError(errorMsg).required(errorMsg)
         : null,
   })

   const {
      update,
      add,
      remove,
      isSuccessUpdate,
      isErrorUpdate,
      isSuccessCreate,
      isSuccessRemove,
      isCreating,
      isUpdating,
      isRemoving,
   } = useNoteMutations()

   const updateStatusConstants = {
      IS_UPDATE_SUCCESS: isSuccessUpdate,
      IS_UPDATE_ERROR: isErrorUpdate,
   }

   useEffect(() => {
      if (crudItemsParentPageView === CrudItemsParentView.Actions) {
         setIsReadonly(true)
      }
   }, [crudItemsParentPageView])

   useEffect(() => {
      if (
         crudItemsParentPageView === CrudItemsParentView.Items &&
         toolLoanId &&
         !selectedItem?.ToolLoanId
      ) {
         setSelectedItem({ ...selectedItem, ToolLoanId: toolLoanId })
         // this property only needs adding if coming from the items page and creating a note on an already selected item
         // the handle dropdown method below does not need to do this setting state without checking for existing toolLoanId will cause an infinite loop
      }
   }, [selectedItem, toolLoanId])

   useEffect(() => {
      if (attachmentData.length) {
         setAttachments((prevState) => [...prevState, ...attachmentData])
      }
   }, [attachmentData])

   useEffect(() => {
      if (selectedNote?.Attachments && selectedNote.Attachments.length) {
         setAttachments((prevState) => [
            ...prevState,
            ...selectedNote.Attachments,
         ])
      }
   }, [selectedNote?.Attachments])

   useEffect(() => {
      if (importFiles) {
         // remove unwantedimports
         const importFilesArray = Array.from(importFiles).filter(
            (file) =>
               file.type.includes('image') ||
               file.name.includes('.doc') ||
               file.name.includes('.docx') ||
               file.name.includes('.pdf')
         )

         const formData = new FormData()
         importFilesArray.map((file, index) => {
            formData.append(`file[${index}]`, file)
         }) // @ts-ignore
         upload(formData)
      }
   }, [importFiles])

   useEffect(() => {
      if (isUploadError) {
         setSnackBarMessage('Error uploading file')
         setSnackBarSeverity(Severity.ERROR)
      }
   }, [isUploadError])

   const prepareNoteForApi = (values) => {
      const attachmentsToAdd = attachments.map((attachment) => attachment.Id)
      return {
         ...values,
         Odometer: parseFloat(values?.Odometer.replace(/,/g, '')),
         Hours: parseFloat(values?.Hours.replace(/,/g, '')),
         ...(values?.CompletedCost && {
            CompletedCost: parseFloat(values?.CompletedCost.replace(/,/g, '')),
         }), // conditionally add completedCost if note completed and submitted
         Attachments: attachmentsToAdd,
      }
   }

   const { onSubmit, validationErrors, onReset } = useForm(
      noteSchema,
      (values) => {
         if (values?.Id) {
            update(prepareNoteForApi(values))
         } else {
            add(prepareNoteForApi(values))
         }
      }
   )

   useEffect(() => {
      if (isSuccessUpdate || isSuccessCreate || isSuccessRemove) {
         refetchNotes()
         resetAndCloseModal()
      }
      getUpdateStatus && getUpdateStatus(updateStatusConstants)
   }, [isSuccessUpdate, isErrorUpdate, isSuccessCreate, isSuccessRemove])

   const resetAndCloseModal = () => {
      setSelectedNote(defaultNote)
      setSelectedItem(defaultItem)
      setSelectedNoteType(defaultNoteType)
      if (setIsNewItemNote) {
         setIsNewItemNote(false)
      }
      refetchNotes() // may need to remove this
      setIsEditModalOpen(false)
   }

   const handleItemDropdownChange = (option: Option) => {
      const item = items.find((item) => item.ID === option.value)
      setSelectedNote((prevState) => ({
         ...prevState,
         ToolId: parseInt(option?.value) ?? '',
         ToolLoanId: item ? item?.ToolLoanId : '',
      }))
      setSelectedItem(item ?? defaultItem)
   }

   const handleNoteTypeDropdownChange = (option: Option) => {
      setSelectedNote((prevState) => ({
         ...prevState,
         NoteTypeId: parseInt(option?.value) ?? '',
      }))
      setSelectedNoteType(option ?? defaultNoteType)
   }

   const handleDateChange = (name: string, date: Date) => {
      setSelectedNote({ ...selectedNote, [name]: date })
   }

   const onNoteTextChange = (e) => {
      setNoteTextValue(e.target.value)
   }

   const handleCompletedToggle = (id: string, checked: boolean) => {
      setNoteCompleted(checked)
      setSelectedNote({
         ...selectedNote,
         CompletedDate: !noteCompleted ? new Date() : '',
      })
   }

   const handleClose = () => {
      onReset()
      resetAndCloseModal()
   }

   const noteItemLabel = () => {
      const title = selectedItem?.Title
      if (selectedItem?.SerialNumber && selectedItem.SerialNumber[0] === '-') {
         selectedItem.SerialNumber = selectedItem.SerialNumber.replace('-', '') // this really needs removing on the back end
      }
      const serial = selectedItem?.SerialNumber
         ? `| Serial # ${selectedItem.SerialNumber}`
         : ''
      const barcode = selectedItem?.Barcode
         ? `| Barcode ${selectedItem?.Barcode}`
         : ''
      const custom =
         settings.CustomText1Enabled && selectedItem.CustomText1Value
            ? `| ${settings.CustomText1Label} ${truncate(
                 selectedItem?.CustomText1Value,
                 40
              )}`
            : ''
      if (selectedItem.Title) {
         return (
            <span
               className={noteStyles.veryBold}
            >{`${title} ${serial} ${barcode} ${custom}`}</span>
         )
      } else {
         return title
      }
   }

   const handleAddAttachment = () => {
      attachmentRef.current.click()
   }

   const handleRemoveAttachment = (attachment: FileAttachment) => {
      removeAttachment({ attachmentId: attachment.Id })
      setAttachments(attachments.filter((file) => file !== attachment))
      //  refetchNotes();
   }

   const handleCheckRemoveAttachment = (e: any, attachment: FileAttachment) => {
      e.preventDefault()
      if (selectedNote?.ToolNotePermission <= 1) return // tool permission view cannot delete attachment
      setAttachmentToRemove(attachment)
      setIsAttachmentWarningModalOpen(true)
   }

   const handleConfirmRemoveAttachment = () => {
      handleRemoveAttachment(attachmentToRemove)
      setIsAttachmentWarningModalOpen(false)
      setAttachmentToRemove(null)
   }

   const dateOptions = { day: 'numeric', month: 'short', year: 'numeric' }
   const noteDate = new Date(selectedNote?.CreatedOn)

   const handleRemoveNotePrompt = () => {
      setIsDeleteNoteWarningModalOpen(true)
   }

   const handleConfirmDeleteNote = () => {
      remove({ id: selectedNote?.Id })
      setIsDeleteNoteWarningModalOpen(false)
   }

   const noteButtons: ItemButton[] = [
      {
         name: 'remove',
         title: 'Delete Note',
         action: () => handleRemoveNotePrompt(),
         overrideViewBox: '.75,.75,3,5',
         type: '',
      },
   ]

   const showLoadingSpinner =
      isCreating ||
      isUpdating ||
      isRemoving ||
      (isNewItemNote && !selectedItem?.Title)

   const generateNoteModalTitle = () => {
      if (
         selectedNote?.Id &&
         selectedNote?.ToolNotePermission !== ToolNotePermission.View &&
         !isReadonly
      ) {
         return 'Edit Note'
      } else if (
         (selectedNote?.Id &&
            selectedNote?.ToolNotePermission === ToolNotePermission.View) ||
         isReadonly
      ) {
         return 'View Note'
      } else {
         return 'Add Note'
      }
   }

   const enableSaveNoteFunctionality = useMemo(() => {
      if (isReadonly) {
         // readonly if viewing from actions page
         return false
      }
      if (selectedNote?.ToolNotePermission <= ToolNotePermission.View) {
         // if is employee and only has permission to view or readonly from actions
         return false
      }
      if (
         selectedNote?.ToolNotePermission > ToolNotePermission.View &&
         !isReadonly
      ) {
         // has permission other than view and not actions page view
         return true
      }
      return true
   }, [isNewItemNote, selectedNote, isReadonly])

   const displayAttachments = useMemo(() => {
      if (
         selectedNote?.ToolNotePermission === ToolNotePermission.View &&
         !selectedNote.Attachments.length
      ) {
         // user can only view and no attachments don't show attachment picker
         return false
      }
      if (isReadonly && !selectedNote.Attachments.length) {
         // if viewing from actions and no notes attached
         return false
      }
      if (
         selectedNote?.ToolNotePermission === ToolNotePermission.View &&
         selectedNote.Attachments.length
      ) {
         // if user can only view and attachments present can view and download
         return true
      }
      return true
   }, [selectedNote, isReadonly])

   const showSnackbar = !!isUploadError

   return (
      <>
         {showLoadingSpinner && <LoadingOverlay />}
         <Modal
            isModalVisible={isEditModalOpen}
            closeModal={handleClose}
            title={generateNoteModalTitle()}
            marginTop={isMobileLayout && '2rem'}
            zIndex={'500'}
            stickyHeader={true}
            itemButtons={selectedNote?.Id && !isReadonly ? noteButtons : []}
         >
            <form
               id="note-form"
               className={noteStyles.form}
               noValidate
               onSubmit={onSubmit}
            >
               <div
                  style={{
                     pointerEvents:
                        selectedNote?.ToolNotePermission <=
                           ToolNotePermission.View || isReadonly
                           ? 'none'
                           : 'auto',
                  }}
               >
                  {selectedNote?.Id && (
                     <input type="hidden" name="Id" value={selectedNote?.Id} />
                  )}
                  <input type="hidden" name="ToolId" value={selectedItem?.ID} />
                  {selectedItem?.ToolLoanId && (
                     <input
                        type="hidden"
                        name="ToolLoanId"
                        value={selectedItem?.ToolLoanId}
                     />
                  )}
                  <input
                     type="hidden"
                     name="NoteTypeId"
                     value={selectedNoteType?.value}
                  />
                  <input
                     type="hidden"
                     name="Completed"
                     value={noteCompleted?.toString()}
                  />
                  <input
                     type="file"
                     hidden
                     id="import"
                     onChange={(event) => setImportFiles(event?.target?.files)}
                     accept="image/*, .doc, .docx, .pdf"
                     multiple={true}
                     ref={attachmentRef}
                  />
                  <Row gutterWidth={20}>
                     {!selectedNote?.Id && (
                        <>
                           <Col xs={12} md={4}>
                              <Dropdown
                                 error={validationErrors?.NoteTypeId}
                                 description={
                                    !selectedNoteType.value
                                       ? 'Used for Reporting (Manage in Settings)'
                                       : ''
                                 }
                                 insetDescription={true}
                                 name="NoteTypeId"
                                 // @ts-ignore
                                 onChange={handleNoteTypeDropdownChange}
                                 defaultValue={
                                    !!selectedNoteType ? [selectedNoteType] : []
                                 }
                                 options={
                                    noteTypes.length
                                       ? convertNoteTypesToOptions(noteTypes)
                                       : []
                                 }
                                 id="NoteTypeId"
                                 label="Note Type"
                                 placeholder="Note Type"
                                 // inputValue={defaultNoteType.label}
                                 isClearable={false}
                                 isMulti={false}
                                 required
                              />
                           </Col>
                           <Col xs={12} md={8}>
                              <span
                                 style={{
                                    pointerEvents:
                                       isNewItemNote || selectedNote?.Id
                                          ? 'none'
                                          : 'auto',
                                 }}
                              >
                                 <Dropdown
                                    error={validationErrors?.ToolId}
                                    name="ToolId"
                                    // @ts-ignore
                                    onChange={handleItemDropdownChange}
                                    options={
                                       items.length
                                          ? convertUserItemsToNoteDropdownOptions(
                                               items,
                                               settings
                                            )
                                          : []
                                    }
                                    defaultValue={[
                                       {
                                          value: selectedItem?.ID.toString(),
                                          label: noteItemLabel(),
                                       } as Option,
                                    ]}
                                    id="ToolId"
                                    label="Item"
                                    placeholder="Item"
                                    isMulti={false}
                                    required
                                    isClearable={false}
                                    excludeOptionValueFromSearch={true}
                                 />
                              </span>
                           </Col>
                        </>
                     )}
                     {selectedNote?.Id && (
                        <>
                           <Col xs={12} md={6}>
                              <div className={noteStyles.noteInfoLeft}>
                                 <h4 className={noteStyles.semiBold}>
                                    {selectedItem?.Title}
                                 </h4>
                                 <h5 className={noteStyles.semiBold}>
                                    {selectedNoteType?.label ?? ''}
                                 </h5>
                                 <div className={noteStyles.infoText}>
                                    {`Added by ${
                                       selectedNote?.CreatedByName ?? ''
                                    } on ${noteDate.toLocaleDateString(
                                       'en-GB',
                                       // @ts-ignore
                                       dateOptions
                                    )}`}
                                 </div>
                              </div>
                           </Col>
                           <Col xs={12} md={6}>
                              <div className={noteStyles.noteInfoRight}>
                                 <div className={noteStyles.infoText}>
                                    {`Serial # ${
                                       selectedItem?.SerialNumber
                                          ? selectedItem.SerialNumber
                                          : '-'
                                    }`}
                                 </div>
                                 <div className={noteStyles.infoText}>
                                    {`Barcode ${
                                       selectedItem?.Barcode
                                          ? selectedItem.Barcode
                                          : '-'
                                    }`}
                                 </div>
                                 {settings.CustomText1Enabled &&
                                    selectedItem?.CustomText1Value && (
                                       <div className={noteStyles.infoText}>
                                          {`${settings.CustomText1Label} ${selectedItem?.CustomText1Value}`}
                                       </div>
                                    )}
                              </div>
                           </Col>
                        </>
                     )}
                  </Row>
                  <Textarea
                     id="NoteText"
                     height={180}
                     label="Note"
                     value={noteTextValue}
                     onChange={onNoteTextChange}
                  />

                  <Row gutterWidth={20}>
                     <Col xs={12} md={4}>
                        <DateInput
                           id="DueDate"
                           label="Due Date"
                           popperPlacement="top-start"
                           description={
                              !selectedNote?.DueDate &&
                              'Send a Notification on this Date'
                           }
                           insetDescription={true}
                           onChange={(date) =>
                              handleDateChange('DueDate', date)
                           }
                           selectedDate={
                              !!selectedNote?.DueDate
                                 ? new Date(Date.parse(selectedNote.DueDate))
                                 : null
                           }
                        />
                     </Col>
                     <Col xs={12} md={4}>
                        <NumericFormat
                           customInput={Input}
                           id="Odometer"
                           label="Odometer"
                           value={selectedNote?.Odometer}
                           maxLength={'11'}
                           thousandSeparator=","
                           allowNegative={false}
                           decimalScale={0}
                        />
                     </Col>
                     <Col xs={12} md={4}>
                        <NumericFormat
                           customInput={Input}
                           id="Hours"
                           label="Hours"
                           value={selectedNote?.Hours}
                           decimalScale={2}
                           thousandSeparator=","
                           allowNegative={false}
                           maxLength={'10'}
                        />
                     </Col>
                  </Row>
                  <Row gutterWidth={20}>
                     <Col xs={12} md={1.5} className={noteStyles.toggleColumn}>
                        <div className={noteStyles.toggle}>
                           <div className={noteStyles.toggleField}>
                              <Toggle
                                 id="Completed"
                                 size="md"
                                 isChecked={noteCompleted}
                                 onToggle={handleCompletedToggle}
                              />
                           </div>
                           <div className={noteStyles.toggleLabel}>
                              Completed
                           </div>
                        </div>
                     </Col>
                     {noteCompleted && (
                        <>
                           <div
                              className={
                                 !isMobileLayout
                                    ? noteStyles.completedInputWrapper
                                    : noteStyles.completedInputWrapperMobile
                              }
                           >
                              <Col xs={12} md={4}>
                                 <Input
                                    id="CompletedBy"
                                    label="Completed By"
                                    value={selectedNote?.CompletedBy}
                                    error={validationErrors?.CompletedBy}
                                 />
                              </Col>
                              <Col xs={12} md={4}>
                                 <DateInput
                                    id="CompletedDate"
                                    label="Completed Date"
                                    popperPlacement="top-start"
                                    withWrappers={true}
                                    onChange={(date) =>
                                       handleDateChange('CompletedDate', date)
                                    }
                                    selectedDate={
                                       !!selectedNote?.CompletedDate
                                          ? new Date(
                                               Date.parse(
                                                  selectedNote?.CompletedDate
                                               )
                                            )
                                          : null
                                    }
                                    error={validationErrors?.CompletedDate}
                                 />
                              </Col>
                              <Col xs={12} md={4}>
                                 <div className={noteStyles.currency}>
                                    <span />
                                    <NumericFormat
                                       customInput={Input}
                                       id="CompletedCost"
                                       label="Completed Cost"
                                       value={selectedNote?.CompletedCost}
                                       decimalScale={2}
                                       thousandSeparator=","
                                       allowNegative={false}
                                       maxLength={'11'}
                                    />
                                 </div>
                              </Col>
                           </div>
                        </>
                     )}
                  </Row>
               </div>
               {displayAttachments && (
                  <NoteAttachmentPicker
                     attachments={attachments}
                     isUploading={isUploading}
                     isDeleteAttachmentPending={isDeleteAttachmentPending}
                     handleAddAttachment={handleAddAttachment}
                     handleCheckRemoveAttachment={handleCheckRemoveAttachment}
                     selectedNotePermission={selectedNote?.ToolNotePermission}
                     selectedNoteHasId={!!selectedNote?.Id}
                     isReadonly={isReadonly}
                  />
               )}
               {enableSaveNoteFunctionality && (
                  <Row gutterWidth={20}>
                     <Col xs={6}>
                        <Button
                           type="submit"
                           form="note-form"
                           minWidth="100%"
                           disabled={isUploading}
                        >
                           Save
                        </Button>
                     </Col>
                     <Col xs={6}>
                        <Button
                           minWidth="100%"
                           variant="tertiary"
                           onClick={handleClose}
                           isReset
                        >
                           Cancel
                        </Button>
                     </Col>
                  </Row>
               )}
            </form>
            {!enableSaveNoteFunctionality && (
               <form className={noteStyles.form}>
                  <Row gutterWidth={20}>
                     <Col xs={12}>
                        <Button
                           minWidth="100%"
                           variant="tertiary"
                           onClick={handleClose}
                           isReset
                        >
                           Close
                        </Button>
                     </Col>
                  </Row>
               </form>
            )}
         </Modal>
         {isAttachmentWarningModalOpen && (
            <WarningModal
               isModalOpen={isAttachmentWarningModalOpen}
               handleClose={() => setIsAttachmentWarningModalOpen(false)}
               title="Remove Attachment"
               message="Are you sure?"
               primaryButtonTitle="Yes"
               primaryButtonMethod={handleConfirmRemoveAttachment}
               primaryButtonVariant="danger"
               secondaryButtonTitle="No"
               secondaryButtonMethod={() =>
                  setIsAttachmentWarningModalOpen(false)
               }
               secondaryButtonVariant="tertiary"
               zIndex="500"
            />
         )}
         {isDeleteNoteWarningModalOpen && (
            <WarningModal
               isModalOpen={isDeleteNoteWarningModalOpen}
               handleClose={() => setIsDeleteNoteWarningModalOpen(false)}
               title="Delete Note"
               message="Are you sure you wish to delete this note?"
               primaryButtonTitle="Delete"
               primaryButtonMethod={handleConfirmDeleteNote}
               primaryButtonVariant="danger"
               secondaryButtonTitle="Cancel"
               secondaryButtonMethod={() =>
                  setIsDeleteNoteWarningModalOpen(false)
               }
               secondaryButtonVariant="tertiary"
               zIndex="500"
            />
         )}

         <SnackBar
            message={snackBarMessage}
            open={showSnackbar}
            severity={snackBarSeverity}
            disableAutoClose={false}
         />
      </>
   )
}
