import React from 'react';
import classNames from 'classnames';

import { LoadingOverlay } from '..';

import { Props } from './widget.type';
import * as styles from './widget.module.scss';

const Widget: React.FC<Props> = props => {
    const { children, className, heading, hideSpinner = false, isLoading = false } = props;

    return (
        <div className={classNames(styles.wrapper, className)}>
            {heading && <h2 className={styles.heading}>{heading}</h2>}
            {children}
            {!hideSpinner && isLoading && <LoadingOverlay positionAbsolute small />}
        </div>
    );
};

export default Widget;