import React from 'react';
import { ImSpinner4 as Spinner } from 'react-icons/im';

import { Props } from './loadingOverlay.type';
import * as styles from './loadingOverlay.module.scss';

const LoadingOverlay: React.FC<Props> = ({ positionAbsolute, small = false, xs = false, xxs = false }) => {
  return (
      <div className={styles.wrapper} style={positionAbsolute ? { position: 'absolute' } : null}>
        <Spinner className={styles.icon} size={small ? 28 : xs ? 16 : xxs ? 10 : 40} />
      </div>
  );
};

export default LoadingOverlay;