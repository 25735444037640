// extracted by mini-css-extract-plugin
export var btn = "button-module--btn--feba9";
export var btnDanger = "button-module--btn-danger--ee1cb";
export var btnIcon = "button-module--btn-icon--27bd2";
export var btnPlain = "button-module--btn-plain--96bab";
export var btnPlainV2 = "button-module--btn-plain-v2--33445";
export var btnPreserveText = "button-module--btn-preserve-text--15fd6";
export var btnPrimary = "button-module--btn-primary--ff364";
export var btnPrimaryAnchor = "button-module--btn-primary-anchor--4c72e";
export var btnSecondary = "button-module--btn-secondary--11f88";
export var btnSmall = "button-module--btn-small--a2768";
export var btnSpinner = "button-module--btn-spinner--9d5c5";
export var btnSuccess = "button-module--btn-success--1437c";
export var btnTertiary = "button-module--btn-tertiary--5cca4";
export var spin = "button-module--spin--11e8b";