import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { Widget } from '..';

import * as styles from './tabbedWidget.module.scss';
import { Props } from './tabbedWidget.type';


const TabbedWidget: React.FC<Props> = props => {
    const { className, defaultTabId, heading, isLoading, hideSpinner, tabItems } = props;
    const [selectedTab, setTab] = useState<string>(defaultTabId || tabItems[0]?.id);

    const handleTabClick = (id: string) => {
        setTab(id);
    }

    const selectedTabObject = tabItems.find(tab => tab.id === selectedTab);

    useEffect(() => {
        setTab(defaultTabId);
    }, [defaultTabId]);

    return (
        <Widget {...({ heading, isLoading, hideSpinner })} className={className}>
            <div className={styles.tabs}>
                {tabItems.map(tab => (
                    <button
                        key={tab.id}
                        onClick={() => handleTabClick(tab.id)}
                        className={classNames(
                            styles.tab,
                            { [styles.tabActive]: selectedTab === tab.id }
                        )}
                    >
                        {tab.label}
                    </button>
                ))}
            </div>
            <div className={styles.tabContentWrapper}>
                {selectedTabObject?.content}
            </div>
        </Widget>
    );
};

export default TabbedWidget;