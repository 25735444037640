import React, {ChangeEvent, useEffect, useState} from 'react';

import { FieldWrapper } from '..';
import { BaseFormFieldProps} from '../form.base.type';
import * as styles from './textarea.module.scss';

interface Props extends BaseFormFieldProps {
    height?: number | string;
}

const Textarea: React.FC<Props> = props => {
    const [inputHasFocus, setHasFocus] = useState(false);
    const {
        className,
        description,
        disabled = false,
        error,
        height = 100,
        id,
        inputProps,
        label,
        noMargin = false,
        onChange,
        required = false,
        withWrappers = true,
        value
    } = props;

    const handleInputFocus = () => {
        setHasFocus(true);
    }

    const handleInputBlur = (event: ChangeEvent<HTMLFormElement>) => {
        if (!event.target.value) {
            setHasFocus(false);
        }
    }

    const renderElement = () =>
        <textarea
            {...(inputProps || {})}
            defaultValue={value}
            className={styles.field}
            aria-invalid={!!error}
            name={id}
            onBlur={handleInputBlur}
            onChange={onChange && onChange}
            onFocus={handleInputFocus}
            style={{ height }}
        />

    useEffect(() => {
        if (!value && parseInt(value) !== 0) {
            setHasFocus(false);
        } else {
            setHasFocus(true);
        }
    }, [value]);

    if (!withWrappers) {
        return renderElement();
    }

    return (
        <FieldWrapper
            className={className}
            description={description}
            disabled={disabled}
            error={error}
            hasFocus={inputHasFocus}
            id={id}
            label={label}
            required={required}
            noMargin={noMargin}
        >
            {renderElement()}
        </FieldWrapper>
    );
};

export default Textarea;