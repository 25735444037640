import React, { useEffect } from 'react';
import { Link } from "gatsby";
import {BsList, BsX} from "react-icons/bs";

import Logo from "../logo";
import * as styles from './head.module.scss';

interface Props {
    isExpanded: boolean;
    logoLinkHref: string;
    onOpenNav: () => void;
}

export const Head: React.FC<Props> = props => {
    const {
        isExpanded = false,
        logoLinkHref,
        onOpenNav
    } = props;
    const logoSize = {
        mobile: '180px',
        desktop: '120px',
    };

    useEffect(() => {
        if (isExpanded) {
            document?.body.classList.add('menu-open');
        } else {
            document?.body.classList.remove('menu-open');
        }
    }, [isExpanded]);

    return (
        <div className={styles.head}>
            <div className={styles.logo}>
                <Link to={logoLinkHref}>
                    <Logo width={logoSize.desktop} />
                </Link>
            </div>
            <div className={styles.logoMobile}>
                <Link to={logoLinkHref}>
                    <Logo textOnly width={logoSize.mobile} />
                </Link>
            </div>
            <button className={styles.toggle} onClick={onOpenNav}>
                {isExpanded ? <BsX /> : <BsList />}
            </button>
        </div>
    );
};