// extracted by mini-css-extract-plugin
export var coordinatesButton = "audits-module--coordinates-button--824ed";
export var deleteForm = "audits-module--delete-form--a20f9";
export var description = "audits-module--description--7638c";
export var divider = "audits-module--divider--d76c5";
export var filterHeading = "audits-module--filter-heading--9d3d9";
export var form = "audits-module--form--2b65b";
export var icon = "audits-module--icon--620c5";
export var importDownloadExample = "audits-module--import-download-example--5c35c";
export var importForm = "audits-module--import-form--83d60";
export var locationsWrapper = "audits-module--locationsWrapper--86c6a";
export var saveCancelReviewButtonSet = "audits-module--saveCancelReviewButtonSet--bdffc";
export var searchWrapper = "audits-module--search-wrapper--0ea0f";
export var searchWrapperFilter = "audits-module--search-wrapper-filter--fb5f5";
export var searchWrapperSearch = "audits-module--search-wrapper-search--a81b4";
export var status = "audits-module--status--0a382";
export var toggle = "audits-module--toggle--3fc57";
export var toggleField = "audits-module--toggle-field--a7a97";
export var toggleLabel = "audits-module--toggle-label--6cc22";
export var warehouseDescription = "audits-module--warehouse-description--dd565";