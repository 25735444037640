import { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { PATH } from '../../constants/global'
import { useAPI } from '..'
import { NotificationTypes } from '../../types'

import {
   UseNotifications,
   UseNotificationsOptions,
   NotificationsResponse,
   NotificationParams,
   UpdateNotificationParams,
} from './types'

export default function ({
   itemsPerPage = 10,
   onChange,
   totalItems = 0,
   notificationType,
}: UseNotificationsOptions): UseNotifications {
   const request = useAPI()
   const [currentPage, setCurrentPage] = useState<number>(1)

   const { data, isLoading, isRefetching, refetch } = useQuery(
      ['notifications', { notificationType }],
      getNotificationsFromAPI
   )

   const { mutate: mutateDismissNotification } = useMutation(
      dismissNotification,
      {
         onSettled: () => {
            refetch()
            onChange && onChange()
         },
      }
   )

   const { mutate: mutateAcceptRejectNotification } = useMutation(
      updateNotification,
      {
         onSettled: () => {
            refetch()
            onChange && onChange()
         },
      }
   )

   async function getNotificationsFromAPI(): Promise<
      AxiosResponse<NotificationsResponse>
   > {
      const params: NotificationParams = {
         skip: (currentPage - 1) * itemsPerPage,
         take: itemsPerPage,
         category:
            notificationType === NotificationTypes.Global
               ? 1
               : notificationType,
         returnCompanyNotifications:
            notificationType === NotificationTypes.Global ? true : undefined,
      }

      try {
         const response = await request.get(PATH.DASHBOARD.NOTIFICATIONS, {
            params,
         })
         return response
      } catch (error) {
         throw error
      }
   }

   async function updateNotification(
      params: UpdateNotificationParams
   ): Promise<AxiosResponse> {
      return await request.post(PATH.DASHBOARD.NOTIFICATION_DISMISS, params)
   }

   async function dismissNotification(id: number): Promise<AxiosResponse> {
      return await request.post(PATH.DASHBOARD.NOTIFICATION_DISMISS, {
         id,
         accept: false,
         delete: true,
      })
   }

   const { mutate: mutateDeleteGlobalNotification } = useMutation(
      deleteGlobalNotification,
      {
         onSettled: () => {
            refetch()
            onChange && onChange()
         },
      }
   )

   async function deleteGlobalNotification(id: number): Promise<AxiosResponse> {
      return request.post(PATH.DASHBOARD.NOTIFICATION_DISMISS, {
         id,
         accept: false,
         delete: true,
      })
   }

   const deleteGlobal = (id: number) => mutateDeleteGlobalNotification(id)

   const acceptReject = (id: number, accept: boolean) =>
      mutateAcceptRejectNotification({ id, accept })

   const dismiss = (id: number) => mutateDismissNotification(id)

   const nextPage = () => setCurrentPage(currentPage + 1)

   useEffect(() => {
      refetch()
   }, [currentPage])

   const list = data?.data?.Data || []

   return {
      isLastPage: list.length === totalItems,
      isLoading: isLoading || isRefetching,
      isLoadingMore: isRefetching,
      list,
      acceptReject,
      dismiss,
      nextPage,
      deleteGlobal,
   }
}
